.special-font {
  font-family: 'Merriweather', serif;
}

/* styles.css */
.header-one {
  font-size: 2em;
  font-weight: bold;
}

.header-two {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 15px; 
}

.unstyled {
  font-size: 1em;
}

.border {
  border-color: beige;
}